<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">員工資料</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <!-- Field: Staff ID -->
          <b-col cols="12" md="12">
            <b-form-group label="員工編號(自動生成)" label-for="id">
              <b-form-input id="id" v-model="userData.id" readonly />
            </b-form-group>
          </b-col>
          <!-- Field: Name -->
          <b-col cols="12" md="6">
            <validation-provider #default="validationContext" name="姓名 (ENG)" rules="required">
              <b-form-group label="姓名 (ENG)" label-for="name_en" class="required">
                <b-form-input id="name_en" v-model="userData.name_en" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Name (CN) -->
          <b-col cols="12" md="6">
            <validation-provider #default="validationContext" name="姓名 (中文)" rules="required">
              <b-form-group label="姓名 (中文)" label-for="name" class="required">
                <b-form-input id="name" v-model="userData.name" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


          <!-- Field: Team -->
          <b-col cols="12" md="6">
            <b-form-group label="職位" label-for="position_id">
              <v-select v-model="userData.position_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="positionOptions" label="position" :reduce="(option) => option.id" :clearable="false" />
            </b-form-group>
          </b-col>

          <!-- Field: 入職日期 -->
          <b-col cols="12" md="6">
            <b-form-group label="入職日期" label-for="day_of_employment">
              <flat-pickr v-model="userData.day_of_employment" class="form-control" :config="{ dateFormat: 'Y-m-d' }"
                placeholder="YYYY-MM-DD" />
            </b-form-group>
          </b-col>

          <!-- Field: 離職日期 -->
          <b-col cols="12" md="6">
            <b-form-group label="離職日期" label-for="last_day_of_employment">
              <flat-pickr v-model="userData.last_day_of_employment" class="form-control"
                :config="{ dateFormat: 'Y-m-d' }" placeholder="YYYY-MM-DD" />
            </b-form-group>
          </b-col>

          <!-- Field: 團隊 -->
          <b-col cols="12" md="6">
            <b-form-group label="團隊" label-for="team_id">
              <v-select v-model="userData.teams" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teamOptions" :clearable="true" label="team_name" :reduce="(option) => option.id" multiple/>
            </b-form-group>
          </b-col>

          <!-- Field: 電話-->
          <b-col cols="12" md="6">
            <b-form-group label="電話" label-for="phone">
              <b-form-input id="phone" v-model="userData.phone" placeholder="電話" />
            </b-form-group>
          </b-col>

          <!-- Field: 年假天數-->
          <b-col cols="12" md="6">
            <b-form-group label="年假天數" label-for="annual_leave_entitlement">
              <b-form-input id="annual_leave_entitlement" v-model="userData.annual_leave_entitlement"
                placeholder="年假天數" />
            </b-form-group>
          </b-col>

          <!-- Field: 當前月薪-->
          <b-col cols="12" md="6">
            <b-form-group label="當前月薪" label-for="monthly_salary">
              <b-form-input id="monthly_salary" v-model="userData.monthly_salary" placeholder="當前月薪" />
            </b-form-group>
          </b-col>

          <!--Field: Is TeamLeader -->
          <b-col cols="12" md="12">
            <b-form-checkbox v-model="userData.is_teamleader" value="1" unchecked-value="0"
              class="custom-control-primary">
              <span :class="{ teamleader_selected: userData.is_teamleader == 1 }">團隊隊長</span>
            </b-form-checkbox>
          </b-col>
          <b-col class="mt-1" cols="12" md="12" v-if="false">
            <b-form-checkbox v-model="userData.is_admin" value="1" unchecked-value="0" class="custom-control-primary">
              <span :class="{ teamleader_selected: userData.is_admin == 1 }">Admin</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr />

        <!-- Header: Personal Info 
          <div class="d-flex mt-2">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-0 ml-50">Personal Information</h4>
          </div>
          -->
        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <!-- Field: 身份證號碼 -->
          <b-col cols="12" md="6">
            <b-form-group label="身份證號碼" label-for="hk_id">
              <b-form-input id="hk_id" v-model="userData.hk_id" placeholder="身份證號碼" />
            </b-form-group>
          </b-col>

          <!-- Field: 姓別 -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="姓別" label-for="gender" label-class="mb-1">
              <b-form-radio-group id="gender" v-model="userData.gender" :options="genderOptions" value="male" />
            </b-form-group>
          </b-col>

          <!-- Field: 電郵 -->
          <b-col cols="12" md="6">
            <validation-provider #default="validationContext" name="電郵" rules="required">
              <b-form-group label="電郵" label-for="email" class="required">
                <b-form-input id="email" v-model="userData.email" placeholder="Email"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: 出生日期-->
          <b-col cols="12" md="6">
            <b-form-group label="出生日期" label-for="date_of_birth">
              <flat-pickr v-model="userData.date_of_birth" class="form-control" :config="{ dateFormat: 'Y-m-d' }"
                placeholder="YYYY-MM-DD" />
            </b-form-group>
          </b-col>

          <!-- Field: 住家地址-->
          <b-col cols="12" md="6">
            <b-form-group label="住家地址" label-for="home_address">
              <b-form-input id="home_address" v-model="userData.home_address" placeholder="住家地址" />
            </b-form-group>
          </b-col>

          <!-- Field: 手提電話-->
          <b-col cols="12" md="6">
            <b-form-group label="手提電話" label-for="mobile">
              <b-form-input id="mobile" v-model="userData.mobile" placeholder="手提電話" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" v-if="userData.id == 0">
            <h4 class="mb-0">密碼</h4>
            <hr />
          </b-col>
          <!-- Field: 密碼 -->
          <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider #default="validationContext" name="密碼" rules="required|min:8">
              <b-form-group class="required" label="密碼" label-for="password">
                <b-form-input id="password" type="text" :state="getValidationState(validationContext)" v-model="userData.password" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: 確認密碼 -->
          <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider #default="validationContext" name="確認密碼" rules="required|min:8|confirmed:密碼">
              <b-form-group class="required" label="確認密碼" label-for="password_confirmation">
                <b-form-input id="password_confirmation" type="text" :state="getValidationState(validationContext)" v-model="userData.password_confirmation" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider #default="validationContext" name="Role" rules="required">
              <b-form-group label-for="user-role" label="用戶分類">
                <v-select v-model="userData.roles" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions" :clearable="false" label="name" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col cols="12" md="6">

            <b-form-group label-for="is_active">
              <b-form-checkbox checked="true" v-model="userData.is_active" class="custom-control-success"
                name="check-button" switch inline />
              {{ (userData.is_active) ? '啟用' : '禁用' }}
            </b-form-group>

          </b-col>
        </b-row>
        <!-- Button: Submit & Reset Button.-->
        <b-row class="mt-2">
          <b-col>
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" type="submit">
              儲存
            </b-button>
            <b-button variant="outline-secondary" class="mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="resetData">
              重設
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="hide">
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  methods: {
    hide() {
      this.$router.replace("/users/list");
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log(typeof this.userData.teams )
          console.log(this.userData.teams)


          if (typeof this.userData.teams == 'object') {
            if (this.userData.teams && this.userData.teams.length > 0) {
              this.userData.team_id = this.userData.teams[0].id
            } else {
              this.userData.team_id = null
            }
          } else {
            this.userData.team_id = this.userData.teams
          }
          var name = this.userData.roles.name
          if (!name) name = this.userData.roles[0].name

          this.userData.role_name = name

          store
            .dispatch("app-user/updateUser", this.userData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const positionOptions = ref([]);
    const teamOptions = ref([]);

    const genderOptions = [
      { text: "Male", value: "m" },
      { text: "Female", value: "f" },
    ];

    const contactOptionsOptions = ["Email", "Message", "Phone"];

    const resetData = () => {
      emit("refetch-data");
    };
    const resetuserData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const fetchPositionData = () => {
      store
        .dispatch("app-user/fetchPositions")
        .then((response) => {
          positionOptions.value = response.data.positions;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            positionOptions.value = undefined;
          }
        });
    };

    const fetchTeamData = () => {
      store
        .dispatch("app-user/fetchTeams")
        .then((response) => {
          teamOptions.value = response.data.teams;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            teamOptions.value = undefined;
          }
        });
    };

    const roleOptions = ref([]);

    const fetchRoleOptions = () => {
      store
        .dispatch("app-user/fetchRoles")
        .then((response) => {
          roleOptions.value = response.data.roles;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            teamOptions.value = undefined;
          }
        });
    };

    onMounted(() => {
      fetchPositionData();
      fetchRoleOptions();
      fetchTeamData();
      console.log(props.userData);
    });

    return {
      genderOptions,
      contactOptionsOptions,
      positionOptions,
      teamOptions,
      roleOptions,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetuserData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}
</style>
